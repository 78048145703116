body {
    overflow-x: hidden;
}

/* kiosk-app container */
.kiosk-app-container {
    /* height: 100vh; */
    width: 100vw;
    /*     background-image: url(../../../public/images/banner1_bg.jpg);
    background-position: cover;
    opacity: 0.9; */
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.kiosk-app-container .logout-button {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    scale: 1.5;
}

.kiosk-app-container .logout-button i {
    opacity: 1;
}

.kiosk-app-container .logout-button i:hover {
    color: white
}

.kiosk-app-container .logout-button i:focus {
    color: white
}

.kiosk-app-container .form-container {
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    z-index: 9;
    margin-top: 100px;
    margin-bottom: 50px;
    /* opacity: 2; */
}

.form-group {
    margin-bottom: 0;
}

/* General form styles */
.kiosk-booking-form {
    background-color: inherit;
    padding: 0;
}

.kiosk-booking-form .col-md-12,
.kiosk-booking-form .col-sm-12 {
    margin: 15px;
}

.kiosk-booking-form .location-label {
    display: flex;
    justify-content: space-between;
}

.kiosk-booking-form .location-label span {
    text-align: start;
    font-weight: bold;
    color: #333;
    font-size: 1.25em;
    margin-right: auto;
    margin-left: 0;
}

.kiosk-booking-form .location-label {
    text-align: end;
    font-weight: 500;
    color: #e74c3c;
    margin-right: 0;
    margin-left: auto;
}

.kiosk-booking-form .tnc-container {
    display: flex;
    align-items: start;
    margin-top: 15px;
}

.kiosk-booking-form .tnc-container p {
    font-size: 1.15em;
}

.kiosk-booking-form .tnc-container p a {
    cursor: pointer;
    color: #d71b24;
}

.error-message {
    text-align: left;
    font-weight: 500;
    color: #e74c3c;
    margin-right: 0;
    margin-left: auto;
    font-size: 15px;
}

.estimated-trip-fare {
    background: beige;
    text-align: center;
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.estimated-trip-fare strong {
    font-size: 18px;
    color: #000000;
    align-self: center;
}

.display-none {
    display: none;
}

.field-holder {
    border: none;
    /* outline: 1px solid #ddd; */
    position: relative;
}

.field-holderb {
    outline: 1px solid #ddd;
}

.field-holder:focus-within {
    outline: 2px solid #d4a73e;
}

.btn-danger {
    margin-left: 10px;
}

.border {
    border: none;
}

/* Input and button styles */
.location-search-input {
    width: calc(100% - 30px) !important;
    padding: 12px;
    border: none;
    margin-right: 10px;
    font-size: 1.2em;
    outline: none;
}

.location-search-input:focus {
    outline: none;
}

#FROMLOCATION {
    visibility: hidden;
    overflow: hidden;
    height: 0px;
}

input[type="checkbox"] {
    margin: 5.5px 0 0 !important;
    margin-right: 10px !important;
}


.autocomplete-dropdown-container i {
    vertical-align: bottom;
}

.button-space-between {
    display: flex;
    justify-content: space-between;
}

.book-btn {
    background-color: #d4a73e;
    color: white;
    border: none;
    padding: 15px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
}

.book-btn:hover {
    background-color: #b88a32;
}

/* Strong label styles */
strong {
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.autocomplete-dropdown-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    /* border: 1px solid #ddd; */
    border-radius: 4px;
    z-index: 1000;
    position: absolute;
    top: 52px;
}

.input-suggestion {
    padding: 5px;
    border: 1px solid #ddd;
    font-size: 1.1em;
    cursor: pointer;
    text-align: left;
}

.input-suggestion:hover {
    background-color: #ddd !important;
    background: #ddd;
}

.input-suggestion:hover,
.suggestion-item--active {
    background-color: #ddd !important;
}

/* Category selection styles */
.flex-wrapper {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 10px;
    scrollbar-width: thin;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
}

.flex-wrapper::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.flex-wrapper::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

.flex-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.category-width {
    width: 100%;
    height: 180px;
}

.catborder-reserve {
    border: 1px solid #ddd;
    border-radius: 4px;
    text-align: center;
    margin-right: 6px;
    padding: 10px;
    cursor: pointer;
    height: 100%;
    height: 86%;
    width: 160px;
    width: 125px;
    flex-shrink: 0;
    /* Prevent shrinking */
}

.catimg-reserve {
    position: relative;
    top: 0;
}

.modal-actions {
    margin-top: 10px;
}

.catborder-reserve.selectvehicle {
    border: 2px solid #d4a73e;
    background-color: #f8d7da;
}

.fleet-name {
    font-size: 14px;
    margin-bottom: 5px;
}

.catimg-reserve {
    width: 100%;
    height: auto;
    left: 0;
}

/* Terms and conditions styles */
.book_terms-reserve {
    font-size: 13px;
}

.book_terms-reserve a {
    color: #d71b24;
    text-decoration: none;
}

.book_terms-reserve a:hover {
    text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .kiosk-booking-form {
        padding: 15px;
    }

    .location-search-input {
        width: calc(100% - 80px);
    }

    .book-btn {
        padding: 10px 16px;
        font-size: 14px;
    }

    .catborder-reserve {
        /* width: 100px; */
    }

    .fleet-name {
        /* font-size: 12px; */
    }
}

@media (max-width: 576px) {
    .kiosk-booking-form {
        padding: 10px;
    }

    .location-search-input {
        width: calc(100% - 80px);
    }

    .book-btn {
        padding: 8px 12px;
        font-size: 12px;
    }

    .catborder-reserve {
        /* width: 80px; */
    }

    .fleet-name {
        /* font-size: 10px; */
    }
}

/* modal css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 80%;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-header h5 {}

.modal-header .close {
    background: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.close {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

/* trip details css */
.trip-details-container {
    background-color: inherit;
}

/* Heading styling */
.trip-details-container h2 {
    text-align: start;
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
}

/* Table styling */
.trip-details-container .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.trip-details-container .table th,
.trip-details-container .table td {
    padding: 10px;
    text-align: left;
    vertical-align: top;
    border: 1px solid #ddd;
}

.trip-details-container .table th {
    background-color: inherit;
    font-weight: bold;
    font-size: 1.4em;
    color: #555;
}

.trip-details-container .table td {
    background-color: #fff;
    font-size: 1.4em;
    color: #555;
}

/* Special row styling for the thank you message */
.trip-details-container .table th[colspan="2"] {
    text-align: center;
    background-color: inherit;
    font-size: 20px;
    color: #333;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .trip-details-container {
        /* padding: 10px; */
    }

    .trip-details-container h2 {
        font-size: 20px;
    }

    .trip-details-container .table th,
    .trip-details-container .table td {
        padding: 8px;
    }

    .trip-details-container .table th[colspan="2"] {
        font-size: 14px;
    }
}



/* tri[p receipt styles */
.trip-receipt {
    height: 100%;
    margin: 10px;
    padding: 10px;
}

.trip-receipt table tr th .qr-code-container {
    text-align: center;
}

.share-btn {
    background-color: white;
    color: black;
    border: 2px solid #d4a73e;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
}

.kiosk-app-container .booking-button {
    position: absolute;
    top: 25px;
    right: 60px;
    cursor: pointer;
    scale: 1.5;
    padding: 6px 8px;
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
}



.kiosk-app-container .booking-button-trip {
    position: absolute;
    top: 30px;
    right: 105px;
    cursor: pointer;
    scale: 1.5;
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
    border-radius:4px
}

.kiosk-app-container .booking-button-trip button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 6px;
    margin-bottom: 0;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}



.booking-button a {
    color: white !important;
    text-decoration: none !important
}

g{
    fill: #616161 !important;
}

g:hover{
    fill: white !important;
}