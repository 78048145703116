body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}




.idle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure it appears on top */
  background-color: black; /* Optional: Add background to avoid seeing the underlying page */
}

.full-screen-video {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensures the video covers the entire area while maintaining aspect ratio */
}





.opt-code{
  font-size: 100px;
}

@media (max-width:767px) {
  .opt-code{
    font-size: 75px;
  }

  .logo-cl{
    width:60% !important;
    height:60% !important;
  }

}

canvas{
  width: 100px !important;
  height: 100px !important;
}

.ph .form-control{
  display: block;
  width: 100%;
  height: auto !important;
  /* padding: 6px 12px !important; */
  font-size: 14px !important;
  line-height: 1.42857143 !important;
  color: #555 !important;
  background-color: #fff !important;
  background-image: none !important;
  border: none !important;
  border-radius: 4px !important;
  width: calc(100% - 30px) !important;
  padding: 12px;
  border: none !important;
  margin-right: 10px;
  font-size: 1.2em;
  outline: none;
}

 .flag-dropdown{
  background-color: #fff !important;
  border-radius: 0px !important;
}

.form-control:focus {
  border-color: none;
  outline: 0;
  -webkit-box-shadow: none;
 box-shadow: none
}