.table-head {
    width: 100%;
    border: 1px solid #ddd;
    margin-top: 20px;
}

.table-head>tr {
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 10px;
}

.table-head th,
.table-head td {
    padding: 12px 15px;
    text-align: center;
    border: 1px solid grey;
}

.table-head td {
    border: 1px solid grey;
    text-align: center;
}

.table-head>thead {
    border-bottom: 2px solid #777;
}

.table-head>tbody>tr {
    border-bottom: 1px solid #ddd;
}

.table-page {
    display: flex;
    justify-content: space-between;
}

/* .pagination .btn1{
      margin-right: 5px;
   }
   .pagination .btn2{
       margin-left: 5px;
    } */
.pagination>span {
    padding: 3px 5px;
    border: 1px solid grey;
    cursor: pointer;
}

.pagination_selected {
    background-color: rgb(220, 220, 220);
}

.show {
    margin-top: 15px;
}

.page {
    background-color: #337ab7;
    color: #fff;
    border: 1px solid #ddd;
    height: 30px;
}

.button {
    background-color: #fff;
    color: black;
    border: 1px solid #ddd;
    height: 30px;
    width: 70px;
}

.input-suggestion {
    position: relative;
    font-family: "Lato", sans-serif;
    width: 100%;
    min-height: 40px;
    background-color: #fff;
    border: 1px solid black;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
    border-bottom: none;
}

.input-suggestion:last-child {
    border-bottom: 1px solid black;
}

/* .autocomplete-dropdown-container{
      min-height: 500px;
   } */
.input-suggestion .input-type {
    position: absolute;
    left: 25px;
}

/* .gNJUsR{
      overflow-y: hidden !important;
  } */
.jPphKt {
    display: contents;
}

/* start*/
.sc-beqWaB.ewhdCu {
    width: 100%;
    background: none;
    border: 1px solid #000;
}

.sc-aXZVg.jNIkof {
    width: 100%;
    background: none;
    border: 1px solid #000;
}

/* end*/

.sc-dKfzgJ.kTRUaX {
    background: none;
}

/* start*/
.sc-iKGpAt.cOhUY {
    border-top: 1px solid #777;
    background: none;
    width: 100%;
}

.sc-eeDRCY.kqWIVd {
    border-top: 1px solid #777;
    background: none;
    width: 100%;
}

/* end*/

/* start*/
.sc-dmqHEX.jWpMhA {
    background: none;
    border-bottom: none;
}

.sc-eqUAAy.gocYik {
    background: none;
    border-bottom: none;
}

/* end*/
/* start*/
.sc-jsMahE.cKBiPJ {
    background: none;
}

.sc-dhKdcB.hbgCLW {
    background: none;
}

/* end*/
/* start*/
.gSVZoG.dlfHg div:first-child {
    white-space: normal;
}

.dKzMcW.ikexHN div:first-child {
    white-space: normal;
}

/* end*/
/* start*/
.einClO.jUfptO,
.sc-lnAgIa.grsa-Dj,
.einClO.cBxjhB {
    font-weight: 500;
    color: black;
}

.sc-kOHTFB.isLfJH,
.sc-jsJBEP.svYMe,
.sc-iHGNWf.sc-dtBdUo {
    font-weight: 500;
    color: black;
}

/* end*/
/* start*/
.sc-irTswW.dqfNny,
.dpxigv.gSVZoG {
    border: 1px solid #777;
    /* text-align: center;
    justify-content: center; */
    background: white !important;
}

.dpxigv {
    border: 1px solid #777;

}

.sc-iGgWBj.dKzMcW,
.sc-dAlyuH.cygFkb {
    border: 1px solid #777;
    /* text-align: center; */
    /* justify-content: center; */
}

/* end*/
/* start*/
.sc-jsMahE.cKBiPJ:not(:last-of-type) {
    border-bottom: none;
}

.sc-dhKdcB.hbgCLW:not(:last-of-type) {
    border-bottom: none;
}

/* end*/
/* start*/
.sc-fbJfA.hcgwbo {
    color: #000;
    font-weight: 500;
    font-size: 14px;
    white-space: normal;
    text-align: center;
}

.sc-cwHptR.brFloq {
    color: #000;
    font-weight: 500;
    font-size: 14px;
    white-space: normal;
    text-align: center;
}

/* end*/
/* start*/
.sc-csCMJt.jwTXsE {
    justify-content: center;
}

/* end*/
.buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 5%;
}

.buttons button {
    width: 80px;
    text-align: center;
}

.btn1 {
    background-color: #337ab7;
    border-color: #2e6da4;
    color: #fff;
    width: 20%;
}

.btn2 {
    background-color: #337ab7;
    border-color: #2e6da4;
    color: #fff;
    width: 20%;
    height: 40px;
}

.heading {
    text-align: center;
    font-size: 25px;
    font-weight: 700;
}

@media (max-width: 400px) {
    .heading {
        font-size: 18px;
    }

    .buttons {
        margin-top: 8%;
    }

    .buttons button {
        width: 50px;
    }
}

.sc-eqUAAy.gocYik {
    background-color: #f9f9f9;
}

.inputTextColor {
    background-color: #000000;
}

/* .sc-gueYoa.eQQdoY {
      background: none ;
  } */


.sc-feUZmu{
    padding: 25px !important;
    margin-top:100px !important;
    margin-top: 50px;
  }

  .gNJUsR{
    padding: 25px !important;
    margin-top:100px !important;
    margin-top: 50px;
  }

  .btn-primary{
    background-color: #d4a73e;
    border-color:#d4a73e;
  }

  .btn-primary:hover{
    background-color: #d4a73e;
    border-color:#d4a73e;
  }

  .modal-footer{
    display: flex;
    justify-content: space-between;
  }

  .gSVZoG.dlfHg div:first-child {
    white-space: normal !important;
    width: 100% !important;
    padding-left: 6px !important;
}

/* .dlfHg div:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding-left: 5px;
} */

#cell-3-undefined div:nth-child(1) {
    white-space: normal !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
    width: 100% !important;
    padding-left: 2px !important;
}


#cell-4-undefined div:nth-child(1) {
    white-space: normal !important;
     overflow: hidden !important; 
    text-overflow: ellipsis !important;
    width: 100% !important;
    padding-left: 2px !important;
}

/* .rdt_TableBody{
padding: 20px !important;
} */

.ddrtBk{
    justify-content: center !important;
}

.hZkQgf{
    border:2px solid black !important;
}

.kZLaOC{
    border:2px solid black !important;
}

.iPedkf {
padding: 20 !important;
}